import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/testimonials/two.scss";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import NextArrow from "../arrows/NextArrow";
import PrevArrow from "../arrows/PrevArrow";

const TestimonialsTwo = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const mobile = useMediaQuery("(max-width:800px)");

  const settingsMobile = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => (
      <div
        style={{
          width: "100%",
          color: "blue",
          position: "relative",
          placeSelf: "center",
        }}
      >
        <div style={{ opacity: "0", cursor: "pointer" }}>{i}</div>
        <span
          style={{
            position: "absolute",
            right: mobile ? "1rem" : "4rem",
            cursor: "pointer",
            // top: "-3.5rem",
            backgroundColor: i === currentSlide ? "#0781fe" : "#343638",
            height: "1.5rem",
            width: "1.5rem",
            borderRadius: "100%",
          }}
        ></span>
      </div>
    ),
  };

  const reviews = [
    {
      name: "Scott Smith",
      image:
        "https://cdn.britannica.com/25/4825-004-F1975B92/Flag-United-Kingdom.jpg",
      title: "User",
      review:
        "Amazing broker in all areas! I spent great deal of time researching different brokers. I wanted a regulated one I could trust",
      src: "https://blueberrymarkets.com/static/assets/img/reviews/tp-review.svg",
    },
    {
      name: "Vincent",
      image: "https://colourfastgraphics.co.uk/wp-content/uploads/Franch.jpg",
      title: "User",
      review:
        "Excellent broker! Alpha Trade Finance has everything you can ask for, I'm supper happy it has been recommended to me as my first broker",
      src: "https://blueberrymarkets.com/static/assets/img/reviews/fpa-review.svg",
    },
    {
      name: "Mr C Bunch",
      image:
        "https://cdn.britannica.com/25/4825-004-F1975B92/Flag-United-Kingdom.jpg",
      title: "Trader",
      review:
        "Top Level Broker. Opening an account was rapid and hastle free just normal ID requirements. Received a friendly call from my account.",
      src: "https://blueberrymarkets.com/static/assets/img/reviews/tp-review.svg",
    },
    {
      name: "Pro Kittisak",
      image:
        "https://c4.wallpaperflare.com/wallpaper/698/202/493/thailand-flag-national-symbol-thailand-large-flag-flag-of-thailand-hd-wallpaper-preview.jpg",
      title: "Trader",
      review:
        "Amazing experience! I have a very nice experience trading with this broker. Execution speed is one of the best all over the market.",
      src: "https://blueberrymarkets.com/static/assets/img/reviews/fpa-review.svg",
    },
    {
      name: "Imani",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_Belgium.svg/692px-Flag_of_Belgium.svg.png",
      title: "Trader",
      review:
        "Just a word to define the 'perfect' broker after hearing about it wanted to test and I do not regret because I discovered a perfect broker!",
      src: "https://blueberrymarkets.com/static/assets/img/reviews/tp-review.svg",
    },
    {
      name: "Stefan Ivarsen",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/f/f1/Flag_of_Norway.png",
      title: "Trader",
      review:
        "Credible and trustworthy! I have been with Alpha Trade Finance for more than three years, feeling secure and comfortable. They are very professional.",
      src: "https://blueberrymarkets.com/static/assets/img/reviews/tp-review.svg",
    },
    // {
    //   name: "Lesley Mtombeni",
    //   image:
    //     "https://cdn.britannica.com/27/4227-004-32423B42/Flag-South-Africa.jpg",
    //   title: "Trader",
    //   review:
    //     "Absolutely one of the best! Ok margins for retail traders, very helpful and friendly customer support, very easy depositing and withdrawing funds.",
    //     src: "https://blueberrymarkets.com/static/assets/img/reviews/google-review-blue.svg"
    //   },
  ];

  return (
    <>
      <div className="testimonials__two__container">
        {/* <div className="testimonials__two__wrapper"> */}
        <Slider
          {...settingsMobile}
          afterChange={(e) => setCurrentSlide(e)}
          className="testimonials__two__wrapper"
        >
          {reviews.map((review) => (
            <div className="testimonials__two__content">
              <h1 className="testimonials__two__review">{review.review}</h1>
              <div className="testimonials__two__user__wrapper">
                <figure className="testimonials__two__figure">
                  <img src={review.image} alt="avatar" />
                </figure>

                <span className="testimonials__two__user__name__wrapper">
                  <p className="testimonials__two__user__name">{review.name}</p>
                  {/* <p className="testimonials__two__user__title">
                    {review.title}
                  </p> */}
                </span>

                <figure style={{ width: "auto", height: "3rem" }}>
                  <img
                    style={{ width: "18rem" }}
                    src={review.src}
                    alt="avatar"
                  />
                </figure>
              </div>
            </div>
          ))}
        </Slider>
        {/* </div> */}
      </div>
    </>
  );
};

export default TestimonialsTwo;
