import "../styles/cta/two.scss";

const CTATwo = () => {
  return (
    <div className="cta__two__container">
      <div className="cta__two__wrapper">
        <span className="cta__two__texts">
          <h1 className="cta__two__text">You're making a difference</h1>
          <p className="cta__two__supporting__text">
            2% of our commission goes to helping bring clean water to families.
          </p>

          {/* <br /> */}
          <a
            href="https://www.charitywater.org"
            style={{ textDecoration: "underline", color: "#b5c89c" }}
          >
            CharityWater.org
          </a>
        </span>

        <span className="cta__two__buttons">
          <button className="cta__two__button__blue">
            <a href="https://app.alphatradefinance.io">Get Started</a>
          </button>
        </span>
      </div>
    </div>
  );
};

export default CTATwo;
