import { useEffect } from "react";
import Header from "../components/Header";
import HeaderWhite from "../components/HeaderWhite";
import CTATwo from "../cta/CTATwo";
import FooterSeven from "../footer/FooterSeven";
import "../styles/content/privacy-dark.scss";

const PrivacyDark = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="privacy__dark__container">
        <div className="privacy__dark__wrapper">
          <div className="privacy__dark__intro">
            <p className="privacy__dark__intro__tag">Privacy Policy</p>

            <h1 className="privacy__dark__intro__text">
              Your data is secure, we promise. Here's how we make sure
            </h1>

            <p className="privacy__dark__intro__supporting">
              We maintain high standards to keep trading environments secure,
              and offer the best tailor made trading conditions for every
              client.
              <br />
              <br />
              We help traders to develop the knowledge and skills they need to
              trade efficiently and responsibly.
            </p>
          </div>

          <div className="privacy__dark__content">
            <div className="privacy__dark__content__one">
              <h1>1. Introduction </h1>

              <p>
                Your privacy is of utmost importance to us and it is our policy
                to safeguard and respect the confidentiality of information and
                the privacy of individuals. The following Privacy Policy sets
                out how Alpha Trade Finance collects, uses and manages your
                personal information we receive by you or a third party in
                connection with our provision of services to you or which we
                collect from your use of our services and/or our website (i.e.,
                alphatradefinance.io) and/or any other related websites and
                applications including, among others, the Client Portal
                (www.app.alphatradefinance.io). This Privacy Policy also informs
                you of your rights with respect to the processing of your
                personal information. By using Alpha Trade Finance websites, you
                give your consent to the collection and use of personal
                information by Alpha Trade Finance as explained in this Privacy
                Policy.
              </p>

              <h1>2. Data Collection</h1>

              <p>
                As part of our daily business operations, we need to collect
                personal information from our clients and prospective clients in
                order to provide them with our products and services. When you
                register for either a demo or a live account with Alpha Trade
                Finance, we collect your personal information directly from you
                and indirectly as a result of your relationship with us. By
                understanding your financial needs, we can treat you fairly as a
                client; we can provide you with the most suitable products and
                services, give you the appropriate information on investment
                strategies, process your requests and transactions and offer you
                both sales and post-sales services. We request the following
                Personal Data from you: Personal and financial information
                needed during the online registration applications/forms;
                Documents needed for KYC and AML procedures as proof of your
                identity and residency such as Passport, National ID, utility
                bills, bank or credit card statements; Set of corporate
                documents in case of corporate clients. Please note that if you
                decide to end the business relationship with Alpha Trade
                Finance, we must keep your Personal Data on record for the next
                five years.
              </p>

              <h1>3. Use of your Personal Data</h1>

              <p>
                Alpha Trade Finance shall use your Personal Data for next
                purposes: To verify your identity. To ensure that you meet the
                suitability requirements needed to use our products and
                services. To manage the account you have with us. To process
                your transactions from and to payment service providers (PSPs)
                and/or banking institutions. To process your trading orders. To
                send you information about transaction/post-transaction
                services. To keep you updated with news on our products,
                services and any other information relevant to your business
                relations with Alpha Trade Finance. For website improvement
                purposes. For the analysis of statistical data which will help
                us provide you with better products and services in the future.
              </p>

              <h1>4. Cookies</h1>

              <p>
                Cookies are small text files sent from Web servers that may be
                stored on your computer. They used to track website visitors. We
                use cookies and web beacons (also known as action tags or
                single-pixel gifs), and other technologies (collectively,
                "cookies") to provide us with data we can use to improve your
                experience with us and to know you better. Cookies enable us to
                capture how you arrived at our site, when you return, which
                pages on our site you visit, and to recognize that you are
                already logged on when we receive a page request from your
                browser. We will never share this information with any 3rd
                party. Cookies are readable only by us and do not contain any
                personal information nor do they contain account or password
                information. Alpha Trade Finance may set and access Alpha Trade
                Finance’s cookies on your computer, enabling us to learn which
                advertisements and promotions bring our visitors or prospective
                clients to our website. Alpha Trade Finance or any of its
                departments may use cookies in connection with Alpha Trade
                Finance’s products and services to track your activities on our
                websites. Such information that we collect and share would be
                anonymous and not personally identifiable. Alpha Trade Finance
                may share usage information about visitors to our websites with
                reputable advertising companies for the purpose of targeting our
                Internet banner or video advertisements. All web browsers have
                settings that allow you to block cookies. By visiting our
                website with your browser set to allow cookies, you consent to
                our use of cookies as described above. If you choose to block
                cookies you may use our services but some functions may not work
                as designed
              </p>

              <h1>5. The security of your personal information</h1>

              <p>
                Alpha Trade Finance is committed to safeguarding your Personal
                Data by adhering to strict security standards and using the most
                up to date security technologies. When you open an account with
                us, you are issued a unique account number, User ID and a
                password. Only a limited number of Alpha Trade Finance employees
                who have a need to know this information will have access to
                your account number, User ID, and password. However, please
                remember that you are ultimately responsible for maintaining the
                secrecy of your account number, User ID and password. We
                strongly recommend that you do not disclose this information to
                anyone. We use proper SSL certificates to authenticate our
                websites and secure trading and customer-facing applications,
                allowing you to verify that you are connected to our websites.
              </p>

              <h1>6. Disclosure of your Personal Information</h1>

              <p>
                Alpha Trade Finance shall not disclose any of its clients’
                confidential information to a third party, except: to extent
                that it is required to do so pursuant to any applicable laws,
                rules and/or regulations; if there is a duty to the public to
                disclose; if our legitimate business interests require
                disclosure; or at your request or with your consent or to
                Persons described in this policy. Alpha Trade Finance will
                endeavor to make such disclosures on a ‘need-to-know’ basis,
                unless otherwise instructed by a regulatory authority. Under
                such circumstances, Alpha Trade Finance will notify the third
                party regarding the confidential nature of any such information.
              </p>

              <h1>7. Your Privacy Rights</h1>

              <p>
                You have certain rights in relation to your Personal You have
                certain rights in relation to your Personal Information. You can
                access your Personal Information and confirm that it remains
                correct and up-to-date, request its deletion or choose whether
                or not you wish to receive material from us or some of our
                partners by logging into your Client Portal and submitting a
                ticket accordingly. You are entitled to receive personal
                information free of charge except in the following circumstances
                where we may charge a reasonable fee to cover our administrative
                costs of providing the personal information for: manifestly
                unfounded or excessive/repeated requests, or further copies of
                the same information. Alternatively, we may be entitled to
                refuse to act on the request. Please consider your request
                responsibly before submitting it. We’ll respond as soon as we
                can. Generally, within 1 week of time. Please keep in mind that
                there will be residual information that will remain within our
                databases, access logs and other records, which may or may not
                contain your Personal Information. Please also note that certain
                Personal Information may be exempt from such requests in certain
                circumstances, which may include if we need to keep processing
                your Personal Information to comply with a legal obligation.The
                various rights are not absolute and are subject to certain
                exceptions or qualifications. In order to exercise any of the
                rights described below please contact us at
                support@alphatradefinance.io
              </p>

              <h1>9. Legal Disclaimer</h1>

              <p>
                We reserve the right to disclose your personal information as
                required by law and when we believe that disclosure is necessary
                to protect our rights and/or to comply with a judicial
                proceeding, court order, or legal process served on our Website.
                We will not be liable for misuse or loss of personal information
                resulting from cookies on our website that we don’t have access
                to or control over. We will not be liable for unlawful or
                unauthorized use of your personal information due to misuse or
                misplacement of your passwords, negligent or malicious, however
                contacted.
              </p>

              <h1>8. Modifications</h1>

              <p>
                Alpha Trade Finance reserves the right to update or modify this
                Privacy Policy at any time, without notice, by posting such
                modifications on this web page. Such modifications will be
                effective upon posting. You are deemed to consent to such
                modifications by further using the services after such
                modification.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CTATwo />
      <FooterSeven />
    </>
  );
};

export default PrivacyDark;
