import { useEffect } from 'react';
import Header from '../components/Header';
import CTATwo from '../cta/CTATwo';
import FooterSeven from '../footer/FooterSeven';
import '../styles/content/about-dark.scss';

const AboutDark = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <>
   <Header />
    <div className="about__dark__container">
        <div className="about__dark__wrapper">
            <div className="about__dark__intro">
                <p className="about__dark__intro__tag">About us</p>
                <h1 className="about__dark__intro__text">We bring honesty and transparency to financial services technology</h1>
                <p className="about__dark__intro__supporting">
                Welcome to Alpha Trade Finance, an international online trading firm offering 24 hours access to a diverse range of trading products including forex currency pairs, commodities, futures, indices and stocks.
                <br/> 
                <br/>
                Through Alpha Trade Finance and the world renowned MetaTrader 5 trading platform, you can take advantage of high liquidity, low spreads, mobile trading, technical analysis and even more.</p>
            </div>
            
            <div className="about__dark__content">
                <section className='about__dark__content__one'>
                    <div>
                        {/* <p>
                        We maintain high standards to keep trading environments secure, and offer the best tailor made trading conditions for every client. We help Forex traders to develop the knowledge and skills they need to trade efficiently and responsibly.

        <br /><br />
With our Daily Market analysis, we bring the freshest market insights to our beloved clients every day. Experience it for yourself!
                        </p> */}

                        <h1>Our Name</h1>

                       <p>
                       It’s no coincidence that our name combines two of the most powerful words in language and finance. We want to be the pivot point in your life. The critical juncture that shifts and accelerates you to a completely new level of growth and freedom.
‍
<br />
<br />
{/* Option; the freedom, power, or right to choose something.
<br /> */}
Alpha; the first, beginning, having the highest rank, excess return.
                       </p>

                       <br /><br />

                       {/* <div> */}

<h1>Our Unfair Advantage</h1>

<p>
We know our real unfair advantage has nothing to do with our platform or services. We believe our unfair advantage over everyone else is that we simply care about our traders more than anyone else. By connecting with and learning from like-minded people, we are building a huge community of empowered traders blazing a new path forward together.
</p>
{/* </div> */}
                    </div>
                

                    <figure><img src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80" alt="about us" /></figure>
                </section>

            </div>
        </div>
    </div>
<CTATwo />
<FooterSeven />
    </>
  )
}

export default AboutDark