import Header from './components/Header'
import HeroSix from './hero/HeroSix'
import FeaturesTwo from './features/FeaturesTwo'
import StatsOne from './stats/StatsOne'
import AssetsTwo from './assets/AssetsTwo'
import CTATwo from './cta/CTATwo'
import FooterSeven from './footer/FooterSeven'
import TestimonialsTwo from './testimonials/TestimonialsTwo'
import { useEffect } from 'react'

const Main = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header />
    <HeroSix />
    <br />
    <FeaturesTwo />
    <StatsOne />
    <TestimonialsTwo />
    <AssetsTwo />
    <CTATwo />
    <FooterSeven />
    </>
  )
}

export default Main